'use strict';

angular.module('enervexSalesappApp').service('TodolistsService', function(Todolist, Todo, TodolistComment, Attachment, Job, $stateParams) {
	this.getJob = getJob;
	this.getTodoLists = getTodoLists;
	this.getTodoList = getTodoList;
	this.newTodoList = newTodoList;
	this.deleteTodoList = deleteTodoList;
	this.updateTodolist = updateTodolist;
	this.getTodos = getTodos;
	this.getAllTodos = getAllTodos;
	this.updateTodo = updateTodo;
	this.newTodo = newTodo;
	this.createAttachment = createAttachment;
	this.getComments = getComments;
	this.createComment = createComment;
	this.removeComment = removeComment;
	this.updateComment = updateComment;

	function getJob() {
		return Job.get({
			id: $stateParams.jobId,
			accountId: $stateParams.accountId
		});
	}

	function getTodoLists() {
		return Todolist.query({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId
		});
	}

	function getTodoList() {
		return Todolist.get({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			id: $stateParams.todolistId,
		});
	}

	function newTodo(item) {
		return Todo.save({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			todolistId: $stateParams.todolistId
		}, item);
	}

	function newTodoList(todoList) {
		return Todolist.save({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId
		}, todoList);
	}

	function deleteTodoList(todoList) {
		return Todolist.remove({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			id: todoList._id
		});
	}

	function updateTodolist(todolist) {
		return Todolist.update({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			id: todolist._id
		}, todolist);
	}

	function updateTodo(todo) {
		return Todo.update({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			todolistId: todo.todolist,
			todoId: todo._id
		}, todo);
	}

	function getTodos() {
		return Todo.query({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			todolistId: $stateParams.todolistId
		});
	}

	function getAllTodos(todolist) {
		return Todo.query({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			todolistId: todolist._id
		});
	}

	function createAttachment(fd) {
		return Attachment.save({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId
		}, fd);
	}

	function getComments() {
		return TodolistComment.query({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			todolistId: $stateParams.todolistId,
		});
	}

	function createComment(newComment) {
		return TodolistComment.save({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			todolistId: $stateParams.todolistId
		}, newComment);
	}

	function removeComment(comment) {
		return TodolistComment.remove({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			todolistId: $stateParams.todolistId,
			id: comment._id
		});
	}

	function updateComment(comment) {
		return TodolistComment.update({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			todolistId: $stateParams.todolistId,
			id: comment._id
		}, comment);
	}
});
